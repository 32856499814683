import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react'
import { PaginatedTable } from '../../../../components/paginated-table'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { formatDate, formatDuration, secureFileDownload } from '../../../../utils/functions'
import { OrderedAssessmentRequestsItem, getOrderedAssessmentRequests, setPageNumber, setPageSize } from '../../../report/ordered-assessments/get-ordered-assessment-requests-slice'
import { ExpiredContextMenuItem, InternalNoteContextMenuItem, OnHoldAssessmentsContextMenuItem, ReassignAnalystContextMenuItem } from '../../../report/components/assessments-action-menu'
import { setSelectedAssessmentInternalNote } from '../../../../components/set-internal-notes-slice'
import { MenuListItemProps } from '../../../../components/menu-list-item'
import { getAnalystUsers } from '../../../report/get-analyst-users-slice'
import AnalystUsersList from '../../../report/analyst/analyst-users-list'
import { usePermission } from '../../../../hooks/use-permission'
import { useEventListener } from '../../../../hooks/use-event-listener'
import { AnilityScopes } from '../../../../interfaces/anility-scopes'
import { ExpireAssessmentConfirmationDialog, ExpireAssessmentConfirmationDialogState } from '../../../report/components/expire-assessment-confirmation-dialog'
import AssessmentOnHoldDialogForm, { AssessmentOnHoldDialogState } from '../../../report/components/assessment-on-hold-dialog-form'
import { ActionMenu } from '../../../../components/action-menu'
import { AssessmentFooter } from '../../../report/components/assessment-footer'
import { TableHeader } from '../../../../components/table-header'
import { DETAILS_COLUMNS } from '../../../../utils/table-config'

interface CustomerOrderedAssessmentsProps {
  customerId: string;
  searchValue: string;
  onSearchClick: () => void;
  onResetSearchClick: () => void;
  onSearchTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setShowInternalNotes: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CustomerOrderedAssessments = ({ customerId, searchValue, onSearchClick, onResetSearchClick, onSearchTextChange, setShowInternalNotes }: CustomerOrderedAssessmentsProps) => {
  const status = 'CollectingRequiredInformation'
  const dispatch = useAppDispatch()
  const { getOrderedAssessmentRequestsState, getAnalystUsersState, tokenState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount } = getOrderedAssessmentRequestsState
  const [writeAssignment, writeAssessment] = usePermission([AnilityScopes.Write.Assignment, AnilityScopes.Write.Assessment])
  const [assignmentEnabledId, setAssignmentEnabledId] = useState<number | null>(null)
  const [showExpireAssessmentConfirmationDialog, setShowExpireAssessmentConfirmationDialog] = useState<ExpireAssessmentConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )

  const [showAssessmentOnHoldDialogForm, setShowAssessmentOnHoldDialogForm] = useState<AssessmentOnHoldDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )

  const loadOrderedAssessmentsInCustomers = () => {
    dispatch(getOrderedAssessmentRequests({
      customerId,
      status
    }))
  }

  useEffect(() => {
    if (getOrderedAssessmentRequestsState.loading === 'idle') {
      loadOrderedAssessmentsInCustomers()
    }
  }, [getOrderedAssessmentRequestsState.loading, customerId])

  useEffect(() => {
    dispatch(getAnalystUsers())
  }, [customerId])

  const handleReloadOrderedAssessments = (event: MessageEvent) => {
    if (event.origin === process.env.REACT_APP_API_BASE && event.data.methodToCall === 'reloadOrderedAssessmentsInCustomers') {
      loadOrderedAssessmentsInCustomers()
    }
  }
  useEventListener('message', handleReloadOrderedAssessments)

  const handleExpireAssessmentSubmitSuccess = () => {
    if (!customerId) {
      return
    }
    loadOrderedAssessmentsInCustomers()
    setAssignmentEnabledId(null)
  }

  const handleAssessmentOnHoldSubmitSuccess = () => {
    if (!customerId) {
      return
    }
    loadOrderedAssessmentsInCustomers()
  }

  const handleInternalNotesClick = (item: OrderedAssessmentRequestsItem) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.id)
    )
  }

  const generateContextMenu = (item: OrderedAssessmentRequestsItem): MenuListItemProps[] => {
    return [
      ExpiredContextMenuItem({
        onClick: () => setShowExpireAssessmentConfirmationDialog({
          show: true,
          assessmentRequestId: item.id
        })
      }),
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      }),
      ReassignAnalystContextMenuItem({
        onClick: () => { setAssignmentEnabledId(item.id) }
      }),
      OnHoldAssessmentsContextMenuItem({
        show: item.hasAssignedAnalyst && writeAssessment,
        onClick: () => setShowAssessmentOnHoldDialogForm({
          show: true,
          assessmentRequestId: item.id
        })
      })
    ]
  }

  const handleSearch = () => {
    onSearchClick()
  }

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleViewCustomerSuperform = (item: OrderedAssessmentRequestsItem) => {
    if (!item.requestLink) return
    window.open(`${item.requestLink}?source=ordered_customer`, 'blank')
  }

  const formattedColumns = DETAILS_COLUMNS.map((item) => {
    return {
      ...item,
      onRowClick: item.id === 'assignedAnalyst' && writeAssignment ? () => { } : undefined
    }
  })

  const formattedOrderedAssessments = items.map((item: OrderedAssessmentRequestsItem, index: number) => {
    return {
      ...item,
      orderDate: formatDate(item.orderDate),
      hasAssignedAnalyst: !!item.assignedAnalyst?.label,
      assignedAnalyst: writeAssignment
        ? <AnalystUsersList
          options={getAnalystUsersState.items}
          id={item.id}
          index={index}
          value={item.assignedAnalyst!}
          disabled={!!item.assignedAnalyst?.value && item.id !== assignmentEnabledId}
          onSuccess={() => {
            loadOrderedAssessmentsInCustomers()
            setAssignmentEnabledId(null)
          }}
        />
        : item.assignedAnalyst?.label,
      timeElapsedSinceOrdering: formatDuration(item.timeElapsedSinceOrdering),
      rowInlineStyles: { cursor: 'pointer' }
    }
  })

  const page = useMemo(() => {
    return { totalCount, size: pageSize, number: pageNumber }
  }, [totalCount, pageSize, pageNumber])

  const handleExportButtonClick = () => {
    const params = new URLSearchParams()

    params.append('status', status)
    params.append('search', searchValue)
    params.append('customerId', customerId)

    secureFileDownload(`${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/export?${params.toString()}`, tokenState.token)
  }

  return (
    <>
      <PaginatedTable
        columns={formattedColumns}
        items={formattedOrderedAssessments}
        headerComponent={
          <TableHeader
            searchValue={searchValue}
            onSearchTextChange={onSearchTextChange}
            onSearchClick={handleSearch}
            onResetSearchClick={onResetSearchClick}
            onExportButtonClick={handleExportButtonClick}
            />}
        footerComponent={
          <AssessmentFooter
            page={page}
            onTablePageChange={handlePageChange}
            onTablePageSizeChange={handlePageSizeChange}
          />
        }
        renderMenuRowActions={(item, index, itemLength) => {
          return (
            <ActionMenu
              isContextMenuDisabled={false}
              contextMenuIndicatorMarginLeft={0.6}
              menuListItems={generateContextMenu(item)} index={index} itemLength={itemLength} />
          )
        }}
        onRowClick={handleViewCustomerSuperform}
      />
      <ExpireAssessmentConfirmationDialog
        open={showExpireAssessmentConfirmationDialog.show}
        assessmentRequestId={showExpireAssessmentConfirmationDialog.assessmentRequestId}
        onClose={() => setShowExpireAssessmentConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })}
        onSubmitSuccess={handleExpireAssessmentSubmitSuccess}
      />
      <AssessmentOnHoldDialogForm
        show={showAssessmentOnHoldDialogForm.show}
        onSubmitSuccess={handleAssessmentOnHoldSubmitSuccess}
        assessmentRequestId={showAssessmentOnHoldDialogForm.assessmentRequestId}
        onClose={() =>
          setShowAssessmentOnHoldDialogForm({
            show: false,
            assessmentRequestId: undefined
          })} />
    </>
  )
}
