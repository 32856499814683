import { Box, Chip, LinearProgress, Popper } from '@mui/material'
import React, { useState } from 'react'

interface ProgressBarCellProps {
  row: Record<string, any>,
  progressKey: string
}

export const ProgressBarCell = ({ row, ...props }: ProgressBarCellProps) => {
  const [popperAnchor, setPopperAnchor] = useState<null | HTMLElement>(null)

  const handleOnMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setPopperAnchor(event.currentTarget)
  }

  const handleOnMouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setPopperAnchor(null)
  }

  const progressValue = row[props.progressKey]
  if (progressValue == null) return null

  return <Box
    component="div"
    onMouseEnter={handleOnMouseEnter}
    onMouseLeave={handleOnMouseLeave}
    paddingY="2px"
  >
    <LinearProgress
      variant="determinate"
      value={progressValue}
      sx={{
        '& .MuiLinearProgress-determinate': {
          animation: 'none',
          backgroundImage: 'none',
          backgroundColor: (theme) => theme.palette.grey[50]
        }
      }}
    />
    <Popper
      placement="bottom"
      open={!!popperAnchor}
      anchorEl={popperAnchor}
    >
      <Chip label={`${row[props.progressKey]} %`} />
    </Popper>
  </Box>
}
