import { Box } from '@mui/material'

const RoleInfo = () => {
  const roles = [
    {
      name: 'Owner',
      description: [
        'Can order and view any assessments ordered.',
        'Can invite new team members.',
        'Can assign assessments to Managers, Agents and Viewers.'
      ]
    },
    {
      name: 'Manager',
      description: [
        'Can invite new Agents and Viewers.',
        'Can order and view assessments they ordered.',
        'Can assign assessments to Agents and Viewers'
      ]
    },
    {
      name: 'Agent',
      description: [
        'Can only order and view assessments that they ordered.',
        'Can assign assessments to Viewers.'
      ]
    },
    {
      name: 'Viewer',
      description: [
        'No permissions for ordering any assessments.',
        'Can only access assessments assigned to them.'
      ]
    }
  ]

  const labelStyle = { fontWeight: 600 }
  const boxStyle = { padding: 0.25 }
  const ulStyle = { marginTop: 0 }
  return (
    <>
      {roles.map((role, index) => (
        <Box key={index} sx={boxStyle}>
          <span style={labelStyle}>
            {role.name}:
          </span>
          <ul style={ulStyle}>
            {role.description.map((text, subIndex) => (
              <li key={subIndex}>{text}
              </li>
            ))}
          </ul>
        </Box>
      ))}
    </>
  )
}

export default RoleInfo
