import { ChangeEvent, MouseEvent, SyntheticEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { OrderedAssessmentRequestsItem, getOrderedAssessmentRequests, setPageNumber, setPageSize, setSearchText, resetLoadingStatus } from './get-ordered-assessment-requests-slice'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { formatDate, formatDuration, secureFileDownload } from '../../../utils/functions'
import { Column, PaginatedTable } from '../../../components/paginated-table'
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material'
import { ExpiredContextMenuItem, InternalNoteContextMenuItem, OnHoldAssessmentsContextMenuItem, ReassignAnalystContextMenuItem, TriggerCreateEventContextMenuItem } from '../components/assessments-action-menu'
import { MenuListItemProps } from '../../../components/menu-list-item'
import { AnilityScopes } from '../../../interfaces/anility-scopes'
import { InternalNotes } from '../../../components/internal-notes'
import { setSelectedAssessmentInternalNote } from '../../../components/set-internal-notes-slice'
import { usePermission } from '../../../hooks/use-permission'
import { useEventListener } from '../../../hooks/use-event-listener'
import AnalystUsersList from '../analyst/analyst-users-list'
import { getAnalystUsers } from '../get-analyst-users-slice'
import { ExpireAssessmentConfirmationDialog, ExpireAssessmentConfirmationDialogState } from '../components/expire-assessment-confirmation-dialog'
import { TriggerManualCreatedEventConfirmationDialog, TriggerManualCreatedEventConfirmationDialogState } from '../components/trigger-manual-created-event-confirmation-dialog'
import AssessmentOnHoldDialogForm, { AssessmentOnHoldDialogState } from '../components/assessment-on-hold-dialog-form'
import { ActionMenu } from '../../../components/action-menu'
import { AssessmentFooter } from '../components/assessment-footer'
import { TableHeader } from '../../../components/table-header'
import { useSignalR } from '../../../signalr/signalr-context'
import { DETAILS_COLUMNS, PROGRESS_COLUMNS } from '../../../utils/table-config'

const tabs = {
  details: { id: 'details', label: 'Details' },
  progress: { id: 'progress', label: 'Progress' }
}

const formatColumns = (columns: Column[], canWriteAssignment: boolean) =>
  columns.map((item) => {
    return {
      ...item,
      onRowClick: item.id === 'assignedAnalyst' && canWriteAssignment ? () => { } : undefined
    }
  })

const OrderedAssessments = () => {
  const dispatch = useAppDispatch()
  const {
    getOrderedAssessmentRequestsState, updateExpiredAssessmentState, getAnalystUsersState, assignAnalystUserToAssessmentRequestsState, triggerManualCreatedEventState, tokenState
  } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount, searchText } = getOrderedAssessmentRequestsState
  const isLoading = updateExpiredAssessmentState.loading === 'loading' ||
    assignAnalystUserToAssessmentRequestsState.loading === 'loading' || getAnalystUsersState.loading === 'loading' ||
    triggerManualCreatedEventState.loading === 'loading' || getOrderedAssessmentRequestsState.loading === 'loading'

  const [localSearch, setLocalSearch] = useState(searchText)
  const [showInternalNotes, setShowInternalNotes] = useState(false)

  const [writeInternalNotes, writeAssignment, writeAssessment] = usePermission([AnilityScopes.Write.InternalNotes,
    AnilityScopes.Write.Assignment, AnilityScopes.Write.Assessment])

  const [assignmentEnabledId, setAssignmentEnabledId] = useState<number | null>(null)

  const defaultTab = tabs.details.id
  const [selectedTab, setSelectedTab] = useState<string>(tabs.details.id)
  const [formattedColumns, setFormattedColumns] = useState(formatColumns(DETAILS_COLUMNS, writeAssignment))
  const signalRContext = useSignalR()

  const [showExpireAssessmentConfirmationDialog, setShowExpireAssessmentConfirmationDialog] = useState<ExpireAssessmentConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )
  const [showTriggerManualCreatedEventConfirmationDialog, setShowTriggerManualCreatedEventConfirmationDialog] = useState<TriggerManualCreatedEventConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )
  const [showAssessmentOnHoldDialogForm, setShowAssessmentOnHoldDialogForm] = useState<AssessmentOnHoldDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )

  const handleExpireAssessmentSubmitSuccess = () => {
    if (selectedTab === 'progress') {
      loadOrderedAssessments('ResponseProgressPercentage')
    } else {
      loadOrderedAssessments()
    }
    setAssignmentEnabledId(null)
  }

  const handleAssessmentOnHoldSubmitSuccess = () => {
    dispatch(resetLoadingStatus(false))
  }

  const handleInternalNotesClick = (item: OrderedAssessmentRequestsItem) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.id)
    )
  }

  const generateContextMenu = (item: OrderedAssessmentRequestsItem): MenuListItemProps[] => {
    return [
      ExpiredContextMenuItem({
        onClick: () => setShowExpireAssessmentConfirmationDialog({
          show: true,
          assessmentRequestId: item.id
        })
      }),
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      }),
      ReassignAnalystContextMenuItem({
        onClick: () => { setAssignmentEnabledId(item.id) }
      }),
      OnHoldAssessmentsContextMenuItem({
        show: item.hasAssignedAnalyst && writeAssessment,
        onClick: () => {
          setShowAssessmentOnHoldDialogForm({
            show: true,
            assessmentRequestId: item.id
          })
        }
      }),
      TriggerCreateEventContextMenuItem({
        onClick: () => setShowTriggerManualCreatedEventConfirmationDialog({
          show: true,
          assessmentRequestId: item.id
        })
      })
    ]
  }

  const loadOrderedAssessments = (orderBy?: string, quietLoad?: boolean) => {
    dispatch(getOrderedAssessmentRequests({
      status: 'CollectingRequiredInformation',
      orderBy,
      quietLoad: quietLoad ?? false
    }))
  }

  useEffect(() => {
    setLocalSearch('')
    dispatch(resetLoadingStatus(true))
    setSelectedTab(defaultTab)
  }, [])

  useEffect(() => {
    if (getOrderedAssessmentRequestsState.loading === 'idle') {
      if (selectedTab === 'progress') {
        loadOrderedAssessments('ResponseProgressPercentage')
      } else {
        loadOrderedAssessments()
      }
    }
  }, [getOrderedAssessmentRequestsState.loading])

  useEffect(() => {
    if (getAnalystUsersState.loading === 'idle') {
      dispatch(getAnalystUsers())
    }
    setFormattedColumns(formatColumns(DETAILS_COLUMNS, writeAssignment))
  }, [getAnalystUsersState.loading])

  useEffect(() => {
    if (signalRContext.hubConnection) {
      signalRContext.hubConnection.on('ReloadOrderedAssessmentList', () => {
        if (selectedTab === 'progress') {
          loadOrderedAssessments('ResponseProgressPercentage', true)
        } else {
          loadOrderedAssessments(undefined, true)
        }
      })
      return () => {
        signalRContext.hubConnection!.off('ReloadOrderedAssessmentList')
      }
    }
  }, [signalRContext])

  const handleReloadAssessments = (event: MessageEvent) => {
    if (event.origin === process.env.REACT_APP_API_BASE && event.data.methodToCall === 'reloadOrderedAssessments') {
      if (selectedTab === 'progress') {
        loadOrderedAssessments('ResponseProgressPercentage')
      } else {
        loadOrderedAssessments()
      }
    }
  }
  useEventListener('message', handleReloadAssessments)

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalSearch(event.target.value)
  }

  const handleResetSearch = () => {
    setLocalSearch('')
    dispatch(setSearchText(''))
  }

  const handleSearch = () => {
    dispatch(setSearchText(localSearch))
  }

  const handleViewCustomerSuperform = (item: OrderedAssessmentRequestsItem) => {
    if (!item.requestLink) return
    window.open(`${item.requestLink}?source=ordered`, 'blank')
  }

  const formattedOrderedAssessments = items.map((item, index) => {
    return {
      ...item,
      orderDate: formatDate(item.orderDate),
      hasAssignedAnalyst: !!item.assignedAnalyst?.label,
      assignedAnalyst: writeAssignment
        ? <AnalystUsersList
          options={getAnalystUsersState.items}
          id={item.id}
          index={index}
          disabled={!!item.assignedAnalyst?.value && item.id !== assignmentEnabledId}
          value={item.assignedAnalyst!}
          onSuccess={() => {
            loadOrderedAssessments(selectedTab === 'progress' ? 'ResponseProgressPercentage' : undefined)
            setAssignmentEnabledId(null)
          }}
        />
        : item.assignedAnalyst?.label,
      timeElapsedSinceOrdering: formatDuration(item.timeElapsedSinceOrdering),
      rowInlineStyles: { cursor: 'pointer' }
    }
  })

  const handleExportButtonClick = () => {
    const params = new URLSearchParams()

    params.append('status', 'CollectingRequiredInformation')
    params.append('search', searchText)
    if (selectedTab === 'progress') {
      params.append('tableColumnSet', 'Progress')
      params.append('orderBy', 'ResponseProgressPercentage')
    }

    secureFileDownload(`${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/export?${params.toString()}`, tokenState.token)
  }

  const handleTabChange = (_: SyntheticEvent, tabValue: string) => {
    setSelectedTab(tabValue)
    if (tabValue === 'progress') {
      setFormattedColumns(formatColumns(PROGRESS_COLUMNS, writeAssignment))
      loadOrderedAssessments('ResponseProgressPercentage', false)
    } else {
      setFormattedColumns(formatColumns(DETAILS_COLUMNS, writeAssignment))
      loadOrderedAssessments()
    }
  }

  const page = { totalCount, size: pageSize, number: pageNumber }

  return (
    <>
      <Box p={2} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative'
      }}>
        <Stack spacing={1.5} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography variant='h3'>Ordered</Typography>
          <Typography color={({ palette }) => palette.text.secondary}>Assessments that have been ordered, however are waiting for the entity assessed to respond</Typography>
        </Stack>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label={'ordered-assessment-tabs'}>
          { Object.entries(tabs).map(([_, { id, label }]) =>
            (
              <Tab
                key={id}
                value={id}
                label={label}
                id={`tab-${id}`}
                aria-controls={`tabpanel-${id}`}
                sx={{ textTransform: 'none', width: 200 }}
              />
            ))
          }
          </Tabs>
        </Box>
        <PaginatedTable
          columns={formattedColumns}
          items={formattedOrderedAssessments}
          headerComponent={
            <TableHeader
              searchValue={localSearch}
              onSearchTextChange={handleSearchTextChange}
              onSearchClick={handleSearch}
              onResetSearchClick={handleResetSearch}
              onExportButtonClick={handleExportButtonClick} />}
          footerComponent={
            <AssessmentFooter
              page={page}
              onTablePageChange={handlePageChange}
              onTablePageSizeChange={handlePageSizeChange}
            />
          }
          renderMenuRowActions={(item, index, itemLength) => {
            return (
              <ActionMenu
                isContextMenuDisabled={false}
                contextMenuIndicatorMarginLeft={0.5}
                menuListItems={generateContextMenu(item)} index={index} itemLength={itemLength} />
            )
          }}
          onRowClick={handleViewCustomerSuperform}
        />
      </Box>
      {writeInternalNotes && <InternalNotes open={showInternalNotes} handleClose={() => setShowInternalNotes(false)} />}
      <ExpireAssessmentConfirmationDialog
        open={showExpireAssessmentConfirmationDialog.show}
        assessmentRequestId={showExpireAssessmentConfirmationDialog.assessmentRequestId}
        onClose={() => setShowExpireAssessmentConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })}
        onSubmitSuccess={handleExpireAssessmentSubmitSuccess}
      />
      <TriggerManualCreatedEventConfirmationDialog
        open={showTriggerManualCreatedEventConfirmationDialog.show}
        assessmentRequestId={showTriggerManualCreatedEventConfirmationDialog.assessmentRequestId}
        onClose={() => setShowTriggerManualCreatedEventConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })}
      />
      <AssessmentOnHoldDialogForm
        show={showAssessmentOnHoldDialogForm.show}
        assessmentRequestId={showAssessmentOnHoldDialogForm.assessmentRequestId}
        onSubmitSuccess={handleAssessmentOnHoldSubmitSuccess}
        onClose={() =>
          setShowAssessmentOnHoldDialogForm({
            show: false,
            assessmentRequestId: undefined
          })} />
      <AnilityBackdrop open={isLoading} />
    </>
  )
}

export default OrderedAssessments
