import { Column } from '../components/paginated-table'
import { formatCustomerName } from '../components/customer-name-component'
import { DateTimeElapsedWithHover } from '../components/date-time-elapsed-with-hover'
import { ProgressBarCell } from '../components/progress-bar-cell'

const formatDateTime = (row: Record<string, any>) => <DateTimeElapsedWithHover row={row} dateTimeKey="responseLastModifiedDate" />
const formatProgress = (row: Record<string, any>) => <ProgressBarCell row={row} progressKey="responseProgressPercentage" />

export const DETAILS_COLUMNS: Column[] = [
  {
    id: 'assignedAnalyst',
    label: 'Assigned To',
    minWidth: 220,
    className: 'wrapped-text'
  },
  {
    id: 'orderDate',
    label: 'Date Ordered',
    minWidth: 100,
    align: 'left'
  },
  {
    id: 'reportConfigurationId',
    label: 'Assessment Type',
    minWidth: 90
  },
  {
    id: 'customer',
    label: 'Customer Name',
    minWidth: 150,
    className: 'wrapped-text',
    format: formatCustomerName
  },
  {
    id: 'entityAssessed',
    label: 'Entity Assessed Name',
    minWidth: 175,
    className: 'wrapped-text'
  },
  {
    id: 'contractName',
    label: 'Assessment Subject',
    minWidth: 150,
    className: 'wrapped-text'
  },
  {
    id: 'nominatedContactName',
    label: 'Contact Name',
    minWidth: 125,
    className: 'wrapped-text'
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    minWidth: 125,
    className: 'wrapped-text'
  },
  {
    id: 'nominatedUserEmailAddress',
    label: 'Email Address',
    minWidth: 175,
    className: 'wrapped-text'
  },
  {
    id: 'timeElapsedSinceOrdering',
    label: 'Time Elapsed Since Ordering',
    minWidth: 150,
    className: 'wrapped-text'
  }
]

export const PROGRESS_COLUMNS: Column[] = [
  {
    id: 'assignedAnalyst',
    label: 'Assigned To',
    minWidth: 220,
    className: 'wrapped-text'
  },
  {
    id: 'orderDate',
    label: 'Date Ordered',
    minWidth: 100,
    align: 'left'
  },
  {
    id: 'reportConfigurationId',
    label: 'Assessment Type',
    minWidth: 90
  },
  {
    id: 'responseProgressPercentage',
    label: 'Superform Progress',
    minWidth: 155,
    className: 'wrapped-text',
    format: formatProgress
  },
  {
    id: 'entityAssessed',
    label: 'Entity Assessed Name',
    minWidth: 175,
    className: 'wrapped-text'
  },
  {
    id: 'lastModifiedByUserName',
    label: 'Last Modified By',
    minWidth: 175,
    className: 'wrapped-text'
  },
  {
    id: 'responseLastModifiedDate',
    label: 'User Last Action',
    minWidth: 175,
    className: 'wrapped-text',
    format: formatDateTime
  },
  {
    id: 'responseLastSectionModified',
    label: 'Last Section Actioned',
    className: 'wrapped-text',
    minWidth: 175
  }
]
