import { Box, Button, Grid, Typography } from '@mui/material'
import { ActionStep } from '../components/action-step'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import MDEditor from '@uiw/react-md-editor'
import { BaseReportWorkspaceViewProps } from '../../interfaces/base-report-workspace-view-props'
import { ValidationChecks } from './components/validation-checks'
import { ReportWorkspaceViewConstants } from '../../../interfaces/constants'
import { useMemo } from 'react'
import { markdownToHtml } from '../../../utils/functions'
import parse from 'html-react-parser'

interface FLAReportWorkspaceViewProps extends BaseReportWorkspaceViewProps {
  handleConfirmSpecialRequirements: () => void
 }

export const FLAReportWorkspaceView = ({
  showLineLimitWarning,
  assessmentLocked,
  analystCommentsLineLimit,
  localAnalystComments,
  isAnalystCommentsEditable,
  reportWorkspaceDetails,
  setIsAnalystCommentsEditable,
  handleAnalystCommentChange,
  handleSaveAnalystComments,
  handleMarkAsDone,
  handleConfirmSpecialRequirements
}: FLAReportWorkspaceViewProps) => {
  const hasValidationChecks = !!reportWorkspaceDetails?.validationChecks?.hasValidationChecks
  const validationChecksPassed = !!reportWorkspaceDetails?.validationChecks?.validationChecksPassed
  const generateFinalVersionItems = [
    'Generate a new PDF to review any additional comments and confirm spelling and grammar is accurate',
    'Any changes can be made to the analyst comments in the space above and clicking \'Save\' again',
    'Once complete, click \'Mark as Complete\' below to confirm the assessment is completed'
  ]

  const modifiedGenerateFinalVersionItems = [...generateFinalVersionItems]
  if (hasValidationChecks) {
    modifiedGenerateFinalVersionItems.splice(1, 0, 'Confirm the Validation Checks below has been passed')
  }

  const customerSpecialRequirements = reportWorkspaceDetails?.customerSpecialRequirements
  const entityAssessedSpecialRequirements = reportWorkspaceDetails?.entityAssessedSpecialRequirements
  const hasSpecialRequirements = !!customerSpecialRequirements || !!entityAssessedSpecialRequirements
  const isSpecialRequirementsCheckConfirmed = hasSpecialRequirements ? reportWorkspaceDetails?.specialRequirementsConfirmed : true
  const srCustomerDisplay = useMemo(() => {
    const items = customerSpecialRequirements || ReportWorkspaceViewConstants.SPECIAL_REQUIREMENTS_DEFAULT_TEXT
    const html = markdownToHtml(items)
    return parse(html)
  }, [customerSpecialRequirements])
  const srEntityAssessedDisplay = useMemo(() => {
    const items = entityAssessedSpecialRequirements || ReportWorkspaceViewConstants.SPECIAL_REQUIREMENTS_DEFAULT_TEXT
    const html = markdownToHtml(items)
    return parse(html)
  }, [entityAssessedSpecialRequirements])

  if (hasValidationChecks) {
    modifiedGenerateFinalVersionItems.splice(1, 0, 'Confirm the Validation Checks below has been passed')
  }

  return <>
    <ActionStep stepLabel="Step 1" title="Review Special Requirements" items={[]}>
      <Box color={(theme) => theme.palette.text.secondary}>
        <Typography variant="label" sx={{ textDecoration: 'underline' }}>Entity Requirements</Typography>
        {srEntityAssessedDisplay}
        <Typography variant="label" sx={{ textDecoration: 'underline' }}>Customer Requirements</Typography>
        {srCustomerDisplay}
      </Box>
      {hasSpecialRequirements && (<Grid container alignItems="center">
        <Typography variant="h5" marginRight={2}>Confirm Special Requirements</Typography>
        <Button
          variant="contained" size="small"
          type="button" color="primary"
          disabled={assessmentLocked || isSpecialRequirementsCheckConfirmed}
          onClick={handleConfirmSpecialRequirements}>Confirm</Button>
      </Grid>)}
    </ActionStep>
    <ActionStep stepLabel="Step 2" title="Generate Draft Assessment" items={[
      'Generate a Draft PDF to Review',
      'Review the Report content. This includes Formatting, Commentary, and Financials and Figures',
      'If any changes are required, please lodge a support ticket',
      'If no changes are required, continue to Step 2'
    ]} />
    <ActionStep stepLabel="Step 3" title="Add Analyst Comments" items={[
      'Review the information provided to see if any additional commentary is required. This could include a Qualified Auditor\'s Opinion, Going Concern notes, or Wider Group or Deed of Cross Guarantee concerns for example',
      'Enter any relevant comments in the space below',
      'A new bullet point will represent a red exclamation in the Report',
      `You can add a maximum of ${analystCommentsLineLimit} comments`,
      'Press \'Save\' to add the comments to the Report'
    ]}>
      <Grid container rowGap={2} pb={1}>
        <Grid item container xs={12} rowGap={1}>
          <Grid item xs={6}>
            <Typography variant="h5">Comments</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5">Assessment PDF Preview</Typography>
          </Grid>
          <Grid item xs={12} data-color-mode="light">
            <Box className='custom-list-style'>
              <MDEditor
                value={localAnalystComments}
                onChange={(val) => handleAnalystCommentChange(val)}
                textareaProps={{
                  disabled: !isAnalystCommentsEditable || assessmentLocked
                }}
                hideToolbar />
            </Box>

          </Grid>
        </Grid>
        <Grid item xs container>
          {showLineLimitWarning && (<Typography variant="body2" color={(theme) => theme.palette.text.secondary}>No more comments allowed</Typography>)}
        </Grid>
        <Grid item xs container justifyContent="flex-end">
          {isAnalystCommentsEditable
            ? (<Button
              variant="contained"
              type="button" color="secondary"
              disabled={assessmentLocked}
              onClick={handleSaveAnalystComments}>Save</Button>)
            : (<Button
              variant="contained"
              type="button" color="secondary"
              startIcon={<EditOutlinedIcon />}
              disabled={assessmentLocked}
              onClick={() => setIsAnalystCommentsEditable(true)}>Edit</Button>)}
        </Grid>
      </Grid>
    </ActionStep>
    <ActionStep stepLabel="Step 4" title="Generate Final Version" items={modifiedGenerateFinalVersionItems}>
    {hasValidationChecks && (<ValidationChecks validationChecksPassed={validationChecksPassed} />)}
      <Grid container justifyContent="flex-end" pb={1}>
        <Button
          variant="contained"
          type="button" color="primary"
          disabled={!reportWorkspaceDetails?.reportUrl || assessmentLocked || !isSpecialRequirementsCheckConfirmed}
          onClick={handleMarkAsDone}>Mark As Complete</Button>
      </Grid>
    </ActionStep>
  </>
}
