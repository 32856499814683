import { Box, Chip, Popper } from '@mui/material'
import dayjs from 'dayjs'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import React, { useState } from 'react'
import { formatDuration } from '../utils/functions'
dayjs.extend(AdvancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

interface DateTimeElapsedWithHoverProps {
  row: Record<string, any>,
  dateTimeKey: string
}

export const DateTimeElapsedWithHover = ({ row, ...props }: DateTimeElapsedWithHoverProps) => {
  const [popperAnchor, setPopperAnchor] = useState<null | HTMLElement>(null)

  const tz = dayjs.tz.guess()
  const dateTime = dayjs(row[props.dateTimeKey]).tz(tz)
  const dateTimeString = dateTime.format('YYYY/MM/DD HH:mm:ss z')

  const humanizedTime = formatDuration(Date.now() - (dateTime.unix() * 1000))

  const handleOnMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setPopperAnchor(event.currentTarget)
  }

  const handleOnMouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setPopperAnchor(null)
  }

  if (row[props.dateTimeKey] == null) return null

  return <Box
    component="div"
    onMouseEnter={handleOnMouseEnter}
    onMouseLeave={handleOnMouseLeave}
    paddingY="2px"
  >
    { humanizedTime }
    <Popper
      placement="bottom"
      open={!!popperAnchor}
      anchorEl={popperAnchor}
    >
      <Chip label={dateTimeString} />
    </Popper>
  </Box>
}
